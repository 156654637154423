<template>
  <!-- 明细 -->
  <div class="box">
<!--    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item @change="changeItem" v-model="dropdownValue" :options="typeList" />
    </van-dropdown-menu>-->
    <!-- 列表 -->
    <van-pull-refresh v-show="!billIsNull" v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell v-for="(item,index) in list" :key="index" @click="checkOrder(item)">
          <!-- 使用 title 插槽来自定义标题 --> 
          <template #title>
            <div class="left-box">
              <div class="tradingIcon"><img :src="imgUrls[item.bill_type]" ></div>
              <div style="display: flex;flex-direction: column;">
                <span class="tradingName">{{item.bill_type|titleContent}}</span><span class="tradingTime">{{item.created_at|tradeTime}}</span>
              </div>
            </div>
            <div :class="item.money|moneyColor">{{item.money}}</div>
          </template>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <van-empty v-show="billIsNull" class="custom-image" :image="require('../../../assets/images/icon-null.png')"
      description="暂无明细信息" />
  </div>
</template>

<script>
import {GetFankaList} from '@/api/bill'

import qs from "qs";
  export default {
    name:'Detail',
    data(){
      return {
        imgUrls:{
          '0':require('../../../assets/images/icon-0.jpg'),
          '1':require('../../../assets/images/icon-1.png'),
          '2':require('../../../assets/images/icon-3.png'),
          '3':require('../../../assets/images/icon-6.png'),
          '4':require('../../../assets/images/icon-5.png'),
          '6':require('../../../assets/images/icon-3.png')
        },
        // 账单类型，0:全部，1:订单消费，2:退款，3:温度卡充值，4:餐卡结余，5：扫码付款，6：温度卡退款
        typeList: [
          { text: "全部交易类型", value: 0 },
          { text: "订单消费", value: 1 },
          { text: "订单退款", value: 2 },
          { text: "账户充值", value: 3 },
          { text: "餐卡结余", value:4 },
          { text: "充值退款", value: 6 }
        ],
        dropdownValue: 0,
        refreshing:"",
        loading: false,
        finished: false,
        list:[],
        page: 1,
        size: 10,
        //账单有无数据
        billIsNull: false,
        token:''
      }
    },
  mounted(){
    this.token = this.$route.query.token
    // this.token = 'e318d8a1f9895ddfb00a0f5a0e124e18'
  },
  filters: {
    titleContent(val) {
      // 0:全部，1:订单消费，2:订单退款，3:账户充值，4:餐卡结余，6:充值退款
      let arr = [
        {name:'全部',value: 0},
        {name:'订单消费',value: 1},
        {name:'订单退款',value: 2},
        {name:'账户充值',value: 3},
        {name:'餐卡结余',value: 4},
        {name:'充值退款',value: 6}
      ]
      let result = arr.filter(item=>{
        return val==item.value
      })
      return result[0].name
    },
    tradeTime(val){
      let timeEnd = val.split(' ')[1]
      let dataTime = val.split(' ')[0].split('-')
      let year = dataTime[0]
      let month = dataTime[1]
      let day = dataTime[2]
      // console.log(dataTime[1].split('')[0])
      if(year.length>1) {
        if(year.split('')[0]==0) {
          year = year.split('')[1]
        }
      }
      if(month.length>1) {
        if(month.split('')[0]==0) {
          month = month.split('')[1]
        }
      }
      if(day.length>1){
        if(day.split('')[0]==0) {
        day = day.split('')[1]
        }
      }
      return year+'年'+month+'月'+day+'日'+' '+timeEnd
    },
    moneyColor(val) {
      let isColor = val.split('')
      // console.log(isColor)
      if(isColor[0]=='-') {
        return 'money'
      }else if(isColor[0]=='+') {
        return 'up'
      }
    }
  },
      methods: {

        changeItem(val){
          // console.log(val)
          this.page = 1;
          this.list = [];
          this.finished = false;
          this.loading = true;
          this.onLoad();

        },
        onRefresh(){
          this.page = 1
          this.list = []
          this.finished = false
          this.loading = true
          this.onLoad()
        },
        //加载数据
        onLoad(){
          // let url = location.search
          // let token = location.search.split('=')[1]
          // console.log(token.split('=')[1])
          if (this.refreshing) {
            this.list = [];
            this.page = 1
            this.refreshing = false;
          } 
          let params = {
            // db72ab22e7af3c28fb1caaa7b9bc3f2d
            token: this.token,
            // token: '6f989f62c3c924e1a85f66343617afc4',
            bill_type: 1,
            page: this.page++,
            size: this.size
          }
          // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
          GetFankaList(qs.stringify(params)).then(res=>{
            let {data,code,message} = res
            // console.log(data)
            if(code!=0) return this.$toast(message||'网络请求错误')
            if(data.list&&data.list.length!=0) {
              data.list.forEach((item,index) => {
                this.list.push(data.list[index])
              });
              this.loading = false;
              // this.list = data
              this.billIsNull = false
              if(this.list.length>=data.count) {
                this.finished = true;
              }
            }else {
              this.billIsNull = true
              this.finished = true;
            }
          }).catch(err=>{
            this.billIsNull = true
            this.finished = true;
            return this.$toast('网络请求错误')
          })
        },
        checkOrder(val){
          // if(val.bill_type!=4&&val.bill_type!=5) {
          //   var u = navigator.userAgent;
          //   var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
          //   var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          //   if(isAndroid){
          //     // android
          //     window.AndroidWebView.showInfoFromJs(val)
          //   }
          //   if(isiOS){
          //     // IOS
          //     window.webkit.messageHandlers.showMessage.postMessage(val);
          //   }
          // }else {
          //   //文案
          // }
        }

      }

  }
</script>

<style lang="scss" scoped>
  // #box {

  //   background-color: #f7f7f7;
  // }

  .van-button {
    border: 0;
    width: 100%;
    font-size: 14px;
  }

  .van-button__text {
    display: flex;
    align-items: center;
  }

  .triangle-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid#C7C7C7;
  }

  .van-empty ::v-deep .van-empty__image {
    width: 175px;
    height: 138px;
  }
  .transaction-class {
    height: 50px;
    text-align: center;
    font-size: 16px;
    line-height: 50px;
  }
  /* 列表 */
  .van-pull-refresh {
    border-top: 8px solid #f7f7f7;
  }
  .van-pull-refresh .van-cell {
    height: 76px;
  }
  .van-pull-refresh .van-cell .van-cell__title  {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left-box {
    display: flex;
    align-items: center;
  }
  .tradingName {
    font-size: 14px;
    color: #40404E;
    // font-weight: 700;
  }
  .tradingTime {
    font-size: 12px;
    color: #BFBFC9;
  }
  .money {
    font-size: 16px;
    color: #40404E;
    font-weight: 700;
  }
  .up {
    font-size: 16px;
    color: #FF8420;
    font-weight: 700;

  }
  .tradingIcon {
    width: 44px;
    height: 44px;
    margin-right: 8px;
  }
  .tradingIcon img {
    width: 100%;
    height: 100%;
  }
</style>